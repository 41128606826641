import React from "react";
import { PageHeader, Button, Modal, Radio, Input, Select } from "antd";
import { ACTIVATION_STATUS } from "../../constants";
import "./styles.scss";
import StatusToggler from 'components/UI/Toggler/StatusToggler';
import { AM_TYPES } from "constants/Global";

const CustomerDetailHeader = ({
  customer,
  listOfAM,
  updateAccountManager,
  currentUser,
  deactivateCustomer,
}) => {
  const [isModalVisible, setIsModalVisible] = React.useState(false);
  const [showAmEditModal, setShowAmEditModal] = React.useState(false);
  const [value, setValue] = React.useState("");
  const [greigeAM, setGreigeAM] = React.useState({});
  const [processingAM, setProcessingAM] = React.useState({});
  const [denimAM, setDenimAM] = React.useState({});
  const [comment, setComment] = React.useState("");
  const [amChanges, setAmChanges] = React.useState({
    greige: false,
    processing: false,
    denim: false,
  });
  React.useEffect(() => {
    if (customer?.accountManagers?.length) {
      const accountManagers = customer.accountManagers;  
      if (accountManagers.length === 1) {
        const amObject = {
          newAccountManagerId: accountManagers[0].id,
          name: accountManagers[0].name,
        };
        setGreigeAM(amObject);
        setProcessingAM(amObject);
        setDenimAM(amObject);
      } else {
        setGreigeAM({
          newAccountManagerId: accountManagers[0]?.id || null,
          name: accountManagers[0]?.name || "",
        });
        setProcessingAM({
          newAccountManagerId: accountManagers[1]?.id || null,
          name: accountManagers[1]?.name || "",
        });
        setDenimAM({
          newAccountManagerId: accountManagers[2]?.id || null,
          name: accountManagers[2]?.name || "",
        });
      }
    }
  }, [customer]);
  const onChange = (e) => {
    setValue(e.target.value);
  };
  const onCommentChange = (e) => {
    setComment(e.target.value);
  };
  const showModal = () => {
    setIsModalVisible(true);
  };
  const showAmModal = () => {
    setShowAmEditModal(true);
  };
  const handleOk = () => {
    deactivateCustomer({
      customerId: customer.id,
      deactivationComment: value === "other" ? comment : value,
      userId: currentUser.id,
      actionType: customer?.activationStatus === ACTIVATION_STATUS.activated ? "deactivate" : "activate",
    });
    setIsModalVisible(false);
  };
  const handleAmOk = () => {
    if (!amChanges.greige && !amChanges.processing && !amChanges.denim) {
      return;
    }
    updateAccountManager({
      newGreigeAmId: greigeAM.newAccountManagerId,
      newProcessingAmId: processingAM.newAccountManagerId,
      newDenimAmId: denimAM.newAccountManagerId,
      customerId: customer.id,
      actionType: "editAM",
      userId: currentUser.id,
    });
    setShowAmEditModal(false);
  };
  const handleCancel = () => {
    setIsModalVisible(false);
  };
  const handleAmCancel = () => {
    setShowAmEditModal(false);
  };
  const onSelectChange = (type, e, id) => {
    const selectedAM = { newAccountManagerId: +id.key, name: id.value };
  
    if (type === AM_TYPES.GREIGE) {
      setGreigeAM(selectedAM);
      setAmChanges({ ...amChanges, greige: true });
    } else if (type === AM_TYPES.PROCESSING) {
      setProcessingAM(selectedAM);
      setAmChanges({ ...amChanges, processing: true });
    } else if (type === AM_TYPES.DENIM) {
      setDenimAM(selectedAM);
      setAmChanges({ ...amChanges, denim: true });
    }
  }  
  function showDeactivationReason(){
    if(customer?.activationStatus === ACTIVATION_STATUS.deactivated){
      return `Deactivated due to: ${customer.deactivationComment}`;
    }
    return '';
  }

  const { Option } = Select;
    return (  
    <div>
      <PageHeader
        className="site-page-header"
        onBack={() => window.history.back()}
        title={customer && customer.name}
        extra={[
          <div className="am-container">
            <div className="am-item">
              <span>Greige AM:&nbsp; </span>
              <span className="am-name">
                {customer?.accountManagers?.[0]?.name}
              </span>
            </div>
            <span className="separator">|</span>
            <div className="am-item">
              <span>Processing AM:&nbsp; </span>
              <span className="am-name">
                {customer?.accountManagers?.length === 1
                  ? customer.accountManagers[0]?.name
                  : customer?.accountManagers?.[1]?.name}
              </span>
            </div>
            <div className="am-item">
            <span className="separator">|</span>
              <span>Denim AM:&nbsp;</span>
              <span className="am-name">
                {customer?.accountManagers?.length === 1
                  ? customer.accountManagers[0]?.name
                  : customer?.accountManagers?.[2]?.name}
              </span>
            </div>
          </div>,
          <Button type="link" onClick={showAmModal}>
            Edit AM
          </Button>,
          <Modal
            title={<span className="title-bold">Edit Account Manager</span>}
            visible={showAmEditModal}
            onOk={(e) => handleAmOk(e)}
            onCancel={handleAmCancel}
            width={500}
            okText="Submit" 
            cancelText="Close" 
          >
            <span>
              <h3 className="subtitle">Select New Greige Account Manager</h3>
            </span>
            <Select
              placeholder="Select a person"
              optionFilterProp="children"
              onChange={(e, id) => {
               onSelectChange("greige", e, id);
              }}
              className="dropdown-select"
              defaultValue={customer?.accountManagers?.[0]?.name}
              filterOption={(input, option) =>
               option?.children?.toLowerCase()?.indexOf(input?.toLowerCase()) >= 0
              }
              showSearch
            >
            {listOfAM?.map((am) => (
            <Option key={am.id} value={am.name}>
            {am.name}
             </Option>
             ))}
            </Select>
            <span >
              <h3 className="subtitle select-gap">Select New Processing Account Manager</h3>            
            </span>
            <Select
              placeholder="Select a person"
              optionFilterProp="children"
              onChange={(e, id) => {
                onSelectChange("processing", e, id);
              }}
              className="dropdown-select"
              defaultValue={customer?.accountManagers?.length === 1
              ? customer.accountManagers[0]?.name
              : customer?.accountManagers?.[1]?.name}
              filterOption={(input, option) =>
              option?.children?.toLowerCase()?.indexOf(input?.toLowerCase()) >= 0
              }
              showSearch
            >
            {listOfAM?.map((am) => (
            <Option key={am.id} value={am.name}>
            {am.name}
            </Option>
            ))}
            </Select>
            <h3 className="subtitle select-gap">Select New Denim Account Manager</h3>
            <Select
              placeholder="Select a person"
              optionFilterProp="children"
              onChange={(e, id) => {
                onSelectChange("denim", e, id);
              }}
              className="dropdown-select"
              defaultValue={customer?.accountManagers?.length === 1
                ? customer.accountManagers[0]?.name
                : customer?.accountManagers?.[2]?.name}
              filterOption={(input, option) =>
                option?.children?.toLowerCase()?.indexOf(input?.toLowerCase()) >= 0
              }
              showSearch
            >
            {listOfAM?.map((am) => (
            <Option key={am.id} value={am.name}>
            {am.name}
           </Option>
            ))}
          </Select>
          </Modal>,
          <StatusToggler
            label={'Status'}
            condition={customer?.activationStatus === ACTIVATION_STATUS.activated}
            onFalseLabel={'Deactivate'}
            onTrueLabel={'Activate'}
            onChange={() => showModal()}
          />,
          <Modal
            title={`${
              customer?.activationStatus === ACTIVATION_STATUS.activated ? "Deactivate" : "Activate"
            } ${customer?.name}`}
            visible={isModalVisible}
            onOk={(e) => {(customer?.activeOrders === 0 && customer?.isInternational === 0) && handleOk(e)}}
            onCancel={handleCancel}
            okText={`${
              customer?.activationStatus === ACTIVATION_STATUS.activated ? "Deactivate" : "Activate"
            }`}
          >
            {customer?.activationStatus === ACTIVATION_STATUS.deactivated ? (
              <div>
                <h2>{`Please confirm to activate ${customer?.name}`}</h2>
              </div>
            ) : (
              <div>
                {customer?.isInternational === 1 && (
                  <h2>International customer cannot be deactivated</h2>
                )}
                {customer?.activeOrders > 0 &&
                  customer?.isInternational === 0 && (
                    <h2>{`${customer?.name} has active orders. You cannot deactivate ${customer?.name}.`}</h2>
                  )}
                {customer?.openRequests > 0 && customer?.isInternational === 0 && (
                  <h2>
                    {`Customer has ${customer?.openRequests} active queries.`}
                    <br /> Confirm Deactivate ?
                  </h2>
                )}
                {customer?.activeOrders === 0 &&
                  customer?.isInternational === 0 && (
                    <>
                      <p>Deactivating due to </p>
                      <Radio.Group onChange={onChange} value={value}>
                        <Radio value="Incorrect Customer Details">
                          Incorrect Customer Details
                        </Radio>
                        <br />
                        <Radio value="Payment Issues">Payment Issues</Radio>
                        <br />
                        <Radio value="other">
                          Other
                          {value === "other" ? (
                            <Input
                              className="commentBox"
                              placeholder="Enter other reason"
                              value={comment || ""}
                              onChange={onCommentChange}
                            />
                          ) : null}
                        </Radio>
                      </Radio.Group>
                    </>
                  )}
              </div>
            )}
          </Modal>,
        ]}
      >
        {showDeactivationReason()}
      </PageHeader>
    </div>
  );
};

export default CustomerDetailHeader;
